import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(@Inject(Injector) private readonly injector: Injector) { }

  private get toastService() {
    return this.injector.get(ToastrService);
  }

  handleError(error: any) {
    console.log(error);
  }
}
