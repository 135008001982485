import { Component, OnInit,  OnDestroy, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, interval, map } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  change: boolean = false;
  unsubscribe$ = new Subject();

  constructor(private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly modalService: NgbModal,
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private readonly cdRef: ChangeDetectorRef,) { }

  ngOnDestroy(): void {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.cdRef.detach();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.addMetaTag();
    const checkInternetSpeed$ = interval(1000)
      .pipe(
        map(() => (navigator as any).connection?.effectiveType),
      );

    checkInternetSpeed$.subscribe(speed => {
      if (speed == '4g') this.change = false;

      if ((speed == '3g' || speed == '2g') && !this.change) {
        this.change = true;;
        this.toastr.info("Your internet speed is slow.")
      }
    });

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    window.addEventListener('popstate', () => {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
    });



    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  updateOnlineStatus = () => {
    if (!navigator.onLine) {
      this.toastr.error("Please check your internet.")
    } else {
      this.toastr.success("You are back online.")
    }
  }

  addMetaTag() {
    this.titleService.setTitle('Omni Assist');
    let isProdEnv = environment.production
    if (!isProdEnv) {
      this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }
}